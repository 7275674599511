import React from "react";
import About from "src/sections/About";
import Confirmed from "src/sections/Confirmed";
import Footer from "src/sections/Footer";
import SEO from "src/components/SEO";

import { NAME, BASE_URL } from "src/helpers/config";

const Thanks = () => {
  const seoProps = {
    title: `Obrigado - ${NAME}`,
    description: "Muito Obrigado por se cadastrar.",
    canonical: `${BASE_URL}/obrigado/`,
    image: `${BASE_URL}/meta/og-image-default.jpg`,
  };

  return (
    <div>
      <SEO {...seoProps} />
      <Confirmed />
      <About />
      <Footer />
    </div>
  );
};

export default Thanks;
