import React from "react";
import "./Confirmed.scss";
const Confirmed = () => {
  return (
    <div className="confirmed">
      <div className="banner">
        <a href="/maratona-js-3/dia-1/">
          <img
            src="/images/maratona-js-3/20210912-04.jpg"
            alt="Maratona JS 3"
          />
        </a>
      </div>

      {/* <p>Muito obrigado.</p>
      <p>
        Em breve você irá receber mais informações, por enquanto você pode me
        seguir no <a href="https://emersonbroga.com/instagram">instagram</a>.
      </p>
      <img
        loading="lazy"
        className="illustration"
        src="/static/illustrations/feeling_proud_qne1.svg"
        alt="Cadastro Confirmado"
      /> */}
    </div>
  );
};
export default Confirmed;
